/** text color **/

// body *{-webkit-filter: grayscale(100%); /* webkit */-moz-filter: grayscale(100%); /*firefox*/-ms-filter: grayscale(100%); /*ie9*/-o-filter: grayscale(100%); /*opera*/filter: grayscale(100%);filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1); filter:gray; /*ie9- */}

.white_text{
  color: #fff;
}
.deep_gray_text{
  color: #4d5062;
}
.light_gray_text{
  color: #5f5f5f;
}
.red_text{
  color:red;
}
.red_text_dark{
  color: #b50f00;
}
.text_light_gray{
  color: #999;
}
.text_gray{
  color:#808080;
}

/** background **/
.bg_fff{
  background-color: #fff!important;
}
.bg_f9{
  background-color: #f9f9f9!important;
}
.bg_ef{
  background-color: #efefef;
}
.bg_ddd{
  background-color: #dddddd;
}
.bg_deep_gray{
  background-color: #AAAAAA;
}
.bg_gray{
  background-color: #CCCCCC;
}
.bg_f7{
  background-color: #f7f7f7;
}


/** dispaly **/
.dispaly_inline{
  display: inline;
}

.dispaly_inline_block{
  display: inline-block;
}

.dispaly_block{
  display: inline;
}

.dispaly_table_cell{
  display: table-cell;
}


/** fonts **/
.font_16{
  font-size: 16px;
}

/** font_weight **/
.font_normal{
  font-weight: 400!important;
}
.font_weight_700{
  font-weight: 700;
}

/** opacity **/

/** margin **/
.margin_top_-24{
  margin-top: -24px!important;
}
.margin_top_26{
  margin-top: 26px!important;
}

.margin_top_52{
  margin-top: 52px!important;
}
.margin_top_61{
  margin-top: 61px!important;
}
.margin_top_104{
  margin-top: 104px!important;
}
.margin_top_26_13{
  margin-top: 26px!important;
  @media (max-width: 768px) {
    margin-top: 13px!important;
  }
}
.margin_top_52_26{
  margin-top: 52px!important;
  @media (max-width: 768px) {
     margin-top: 26px!important;
  }
}


.margin_right_0 {
  margin-right: 0!important;
}
.margin_right_3_per {
  margin-right: 3%!important;
}
.margin_right_6_per {
  margin-right: 6%!important;
}

.margin_bottom_0{
  margin-bottom: 0!important;
}

.margin_bottom_52{
  margin-bottom: 52px!important;
}

.margin_bottom_26{
  margin-bottom: 26px!important;
}
.margin_bottom_26_13{
  margin-bottom: 26px!important;
  @media (max-width: 768px) {
    margin-bottom: 13px!important;
  }
}
.margin_bottom_52_26{
  margin-bottom: 52px!important;
  @media (max-width: 768px) {
     margin-bottom: 26px!important;
  }
}

.margin_bottom_78_52{
  margin-bottom: 78px;
  @media(max-width: 768px){
    margin-bottom: 52px;
  }
}

.margin_bottom_104{
  margin-bottom: 104px!important;
}

/** padding **/
.padding_0{
  padding: 0!important;
}
.padding_right_10{
  padding-right:10px!important;
}
.padding_50_0{
  padding: 50px 0px!important;
}

.padding_bottom_0{
  padding-bottom: 0!important;
}

.padding_top_52_26{
  padding-top: 52px!important;
  @media (max-width: 768px) {
    padding-top: 26px!important;
  }
}


/** width **/
.full_width {
  width: 100%;
}
.width_30{
  width: 30px;
}

/** height **/
.height_400{
  height: 400px;
}



/** float**/
.clear_both{
  clear:both;
}

.pull_right {
  float: right !important;
}
.overflow_hidden{
  overflow: hidden;
}


/* text-decoration*/
.text_underline{
  text-decoration: underline;
}
/* border*/
.border_bottom_one{
  border-bottom: 2px solid #555555;
}
.no_border_bottom{
  border-bottom: 0px!important;
}

/* opacity */
.opacity_1{
  opacity: 1 !important;
}

.text-center{
  text-align: center !important;
}


/** corner mark **/
.sup{
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
    top: -8px;;
}

.cursor_pointer{
  cursor: pointer;
}
