// ==============================
//        公共
// ==============================
.item-page {
  article {
    h3 {
      //position: relative;
      margin: 61px 0 9px 0;
      @media (max-width: 768px) {
        margin: 35px 0 9px 0;;
      }
      &.margin_top_0 {
        margin: 9px 0;
      }
      &::after {
        content: '';
        display: block;
        width: 100px;
        height: 2px;
        background-color: #b50f00;
        border: none;
        margin: 9px 0 26px 0;
        //position: absolute;
        //left:0;
        //bottom: -11px;
      }
      &.no_underline {
        &::after{
          content:'';
          display: none;
        }
      }
    }
    header {
      h3 {
        margin: 9px 0;
      }
    }
  }
}

.dx-viewport {
  width: 100%;
}

.thnominwidth th, .thnominwidth td {
  min-width: 0px !important;
}

// 表格
.wraptable {
  .pdf_img_icon {
    width: 16px;
    height: 16px;
  }
  .dvlegendtable td:first-child {
    width: 5%;
  }
  .sub_title {
    td {
      padding-bottom: 0 !important;
      color: #4d5062;
      font-weight: 700;
    }
  }
  .align_top {
    td {
      vertical-align: top;
    }
  }
  .align_middle {
    vertical-align: middle;
  }
  .first_td_weight {
    tr {
      td:first-child {
        font-weight: 700;
        color: #4d5062;
      }
      &.table_title {
        td {
          color: #fff;
        }
      }
    }
  }
  .table_title {
    font-weight: 700;
    color: #fff !important;
    background-color: #AAAAAA;
  }
  .list-view {
    .table_banker{
      background: #fff;
      border-bottom: none;
      td {
        padding:0;
        div{
          vertical-align: top;
          border-bottom: 1px solid #cdd1c3;
          padding: 4px 1.5%;
          min-width: 80px;
          box-sizing: border-box;
          min-height: 27px;
        }
        div:nth-child(even){
          background: #f9f9f9;
        }
      }
    }
  }
  .doc_table{
    margin: 0 0 26px!important;
    table{
      margin-bottom: -2px!important;
      &.list-view {
        tr{
          box-sizing: border-box!important;
        }
        tr:nth-child(even){
          background: #fff!important;
        }
      }
    }
  }

}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
  .option {
    margin-top: 10px;
  }

  .caption {
    font-size: 18px;
    font-weight: 500;
  }

  .option > span {
    margin-right: 10px;
  }

  .option > .dx-widget {
    display: inline-block;
    vertical-align: middle;
  }
}




.btn_select_box{
  .btn{
    outline: 0;
    border: 1px solid
  }
}

// ==============================
//        header
// ==============================
#header {
  .navigation {
    .nav-collapse {
      &.in {
        height: auto;
        min-height: 100px;
        overflow: hidden;
        @media (max-width: 991px) {
          .btn-group {
            display: block;
          }
        }

      }
      .btn-group {
        display: none;
      }
    }
  }
  .select_site {
    li {
      img {
        width: 20px;
        height: 13px;
      }
    }
  }
  .mod-languages {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin-left: 5px;
        margin-right: 5px;
      }
      &.lang-inline {
        padding: 0 10px 13px;
        margin-top: 37px;
        li {
          display: inline;
        }
      }
      &.lang-block {
        display: block;
      }
    }
    img {
      border: none;
    }
    a {
      text-decoration: none;
    }

    @media (max-width: 1250px){
      .langtitle {
        display: none!important;
      }
    }

  }
}

// ==============================
//        观点
// ==============================
.pages_insights_index,.pages_news_index {
  .moduletable {
    .span6 {
      margin-bottom: 26px;
    }
    .span6:nth-child(2n) {
      margin-right: 0px!important;
    }
    .span3 {
      margin-bottom: 26px;
    }
    .span4 {
      margin-bottom: 26px;
    }
    .span4{
      margin-right: 0px;
    }
  }
  .item-page {
    &.read_more {
      article {
        padding-bottom: 26px;
        @media (max-width: 768px) {
          padding-bottom: 13px !important;
        }
        h3 {
          margin: 9px 0px;
          &::after {
            content: '';
            display: none;
          }
        }
      }
    }
  }
}




/* fix for missing Tooltip */
.dxc-tooltip{
    width:100% !important;
    height:100% !important;
}
.pageurl a {
    color: #b50f00;
}
  
/* Customize DV legend table (for holdings page) */
.dvlegendtable td:first-child {
  width:5%;
}  
  
  
  
.font_hgired {
  color: #b50f00;
  }
  
.ul_bottom1em li {
  margin-bottom: 1em;
}
  
.table_thgrey th {
  
 background-color:#dadada;  
}
  
.td_icons a > img {
  margin-right:10px;
}
  
  
// some animations 
.divhidden_active {
     -webkit-transition: opacity 1.5s ease-out;
    transition: opacity 1.5s ease-out;
    opacity: 0;     
    overflow: hidden;
}
  
.divhidden_inactive {
     -webkit-transition: opacity 1.5s ease-out;
    transition: opacity 1.5s ease-out;
    opacity: 1;     
    overflow: inherit;
}

// 下拉选择框，图表
.selectfundclass {
  position: relative;
  width: 20%;
  display: inline-table;
  border: 0px;

  input[readonly]{
    cursor: pointer;
  }

}

.selectfundclass .dx-texteditor-input {
  height: 2em;
}
.dx-viewport {
  width:100%;
}
  
.thnominwidth th, .thnominwidth td{       
    min-width: 0px !important;
}


#pie2 {
  height:400px;
}

#pieasset {
  height:400px;
}
#pieasset2 {
  height:400px;
}
#chart{
height:400px;
margin-bottom:20px;
}
#chart2 {
height:400px;
margin-bottom:50px;
}

.dx-gesture-cover {
display:none !important; 
}


