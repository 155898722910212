/******---- Home --- ****/
input:focus:invalid:focus {
    border-color: rgba(82,168,236,0.8) !important;
    outline: 0 !important;
    outline: thin dotted \9 !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6) !important;
    -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(82,168,236,.6) !important;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6) !important;
}

select {
    height: inherit;
    padding: 8px !important;
    height: 40px !important;
}

.common-banner {
  .btn-box{
     @media (max-width: 768px) {
      margin: 0 0 13px;
    }
  }
  .red-box, .red-box.large{
    margin: 104px 0;
    @media (max-width: 768px) {
      margin: 52px 0 ;
    }
  }
  
}

#home-new,#our-mission-new {

  .float-right{
      float: right;
      width: 48%;
      margin-right: 1%;
  }

  @media (max-width: 768px) {
      .float-right  {
        float: none;
        width: 99%;
      }
    }
}


#home-new {

  .profile-media {
    padding: 52px 0;
  }
  
  #Our-Mission{
    margin-top: 80px;
    .video-container{
      margin-bottom: 52px;
    }
  }
  #Our-Strategies {
    background-color: #b50f00;
    background-image: url('/images/our-strategies.jpg'); 
    color: #fff;  
    h1{
      color: #fff;
      margin-top: 52px;
    }
    .button-box {
      border: solid 2px #fff;
      color: #fff;
    } 
    hr.short {
      background-color: #fff;
    }
  }
  #Our-Philosophy{
    background-color: #f7f7f7; 
     h1{
      margin-top: 52px;
    }
  }

  @media (max-width: 768px) {
    .float-right  {
      float: none;
      width: 99%;
    }
    .profile-media {
      padding: 26px 0;
    }
    #Our-Mission{
      margin-top: 40px;
      .video-container{
          margin-top: 0;
          margin-bottom: 0;
      }
    }
  }
}
#our-philosophy-new {
  .rowTtile{
     margin-top: -26px !important;
  }
  .award{
    .avator {
      text-align: center;
       height: 80px;
    }
  }
}

#our-people-new, .landing-cell,#contact-new {
  #sbox-btn-close{
    top: 35px;
    right: 35px;
  }
  .btn-box{
    .button-box {
      padding: 10px 4% !important;
    }
  }

  #sbox-overlay{
    z-index: 65555;
     opacity: 0.7;
      width: 100%;
       height: 100%;
  }
   #sbox-window{
     position: fixed;
     z-index: 65557; 
     left: 50%; 
     transform: translateX(-50%);
     top: 13%;
     width: 72%;
     max-width: 920px;
     height: 68%;
     max-height: 527px;
     padding-top: 3%;
    #sbox-content{
      .content{
        overflow: scroll;
      }
     

   
    
    }
   }
  .mapModal {
    #sbox-window{
      top:10%!important;
      width: 96.5% !important;
      max-width: 1390px !important;
      height: auto !important;
      max-height: 625px!important;
      padding-top: 2.6%!important;
      padding-bottom: 2.6%!important;

    }
  }
}
#home-new .landing-cell {
   #sbox-window{
     top: 15%;
     width: 75%;
    height: 70%; 
   
   }
   .content_cell{
     margin-top: 5%;
   }

}

#investment-strategies-new{
  .strategiesContainer{
    background-color: #F9F9F9;
    padding: 30px;
     ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        color: #b50f00;
        padding-left: 1.5em;
        text-indent: -1.7em;
        &::before {
          content: "•";
          margin-right: 1.3em;
        }
        .info {
          color: #5f5f5f;
          display: none;
        }
        &:hover {
          color: #5f5f5f;
        }
      }
      li:hover{
        color: #b50f00;
        .info {
           display: inline;
        }
      }
    }
  }
}

#disclaimer-new,#disclaimer-uk-new{
  .submitbox{
    padding: 10px 4.5%;
    margin-right: 3%;
    margin-bottom: 13px;
    border: solid 2px #b50f00;
    color: #b50f00;
    background-color: #ffffff;
    white-space: nowrap;
    display: inline-block;
    vertical-align: baseline;
    font-family: 'Fira Sans',sans-serif,Arial,'Hiragino Sans GB','Microsoft YaHei';
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
  .submitbox:hover {
    color: #fff;
    background-color: #b50f00;
    text-decoration: none;
  }
  .domselect {
    border: 1px solid #005e8d;
  }
  .submit_btn{
    cursor: pointer;
  }

}

#fund-card-view-new,#fund-list-view-new{
  .icon-card_view {
    background: url('/images/icon-card_view.png') left center no-repeat;
  } 
  .icon-list_view {
    background: url('/images/icon-list_view.png') left center no-repeat;
  } 
  .passive-tip{
    font-weight: bold; 
    margin-bottom: 20px;
    font-size: 14px;
    border: solid 1px #4d5062;
    padding: 10px; 
    color: #b50f00;
    p{
      margin: 0 !important;
    }
  }
  .listview_selected {
    color: #ffffff !important;
    background-color: #b50f00 !important;
  }
}



   #contact-new #sbox-btn-close{
     top: 20px;
      right: 35px;
   }




   #disclaimer-new{
     .important-information{
       li{
         display: table;
         padding-left: 0!important;
         &::before{
            display: table-cell;
            width: 10px;
         }
       }
     }
     .important-information-zh{
          ul li:before {
            content: "â€¢ " !important;
            width: 25px;
      }
     }
   }
#fund-card-view-new,#fund-list-view-new{
  .min-width-100{
    min-width: 100px!important;
  }
  .min-width-120{
    min-width: 120px!important;
  }
  .min-width-150{
    min-width: 150px!important;
  }
}
#fund-card-view-new{
  .card-view{
      dt,dd{
        min-height: 18px;
      }
  }
}